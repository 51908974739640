// @flow

import React from 'react';
import axios from 'axios';
import { getTopLevelDomain } from './services/helpers/urlHelpers';

const SESSION_ID_PLACEHOLDER = ':sessionId';
const PIN_PLACEHOLDER = ':pin';

const SECOND_SCREEN_API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://idp-second-screen-int.uefa.com/v3'
    : // this placeholder will get replaced by a build script to inject the right base url
      // for the corresponding environment where the app is being deployed
      'https://idp-second-screen-int.uefa.com/v3';
const GENERATE_PIN_API_URL = `${SECOND_SCREEN_API_BASE_URL}/pin`;
const CHECK_PIN_VERIFIED_API_URL = `${SECOND_SCREEN_API_BASE_URL}/pin/${PIN_PLACEHOLDER}?sessionId=${SESSION_ID_PLACEHOLDER}`;

const styles = {
  wrapper: {
    background: 'grey',
    height: '640px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    margin: '0 auto',
  },

  item: {
    opacity: '0.5',
    background: 'black',
    color: 'white',
    textAlign: 'center',
  },

  code: {
    fontSize: '30px',
    fontWeight: '600',
  },
};

const STATUS = {
  LOCKED: 'LOCKED',
  UNLOCKED: 'UNLOCKED',
};

type PinStatusType = 'LOCKED' | 'UNLOCKED';

const POLLING_INTERVAL = 5000;

type State = {|
  value: ?string,
  status: ?PinStatusType,
  sessionId: ?string,
|};

type Props = {|
  close: Function,
|};

type WebSessionInfo = {|
  cookieName: string,
  cookieValue: string,
|};

type CheckPinResponseStatusType =
  | {|
      status: 'LOCKED',
    |}
  | {|
      status: 'UNLOCKED',
      notifyLogin: {
        sessionInfo: WebSessionInfo,
      },
    |};

class TvMockupScreen extends React.Component<Props, State> {
  pollInterval = null;
  state = {
    value: '',
    sessionId: '',
    status: STATUS.LOCKED,
  };

  async componentDidMount() {
    try {
      const request = await axios.post(
        GENERATE_PIN_API_URL,
        {
          platform: 'BROWSER',
          origin: 'IDP Demo',
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      this.setState({ value: request.data.pinCode.value, sessionId: request.data.sessionId });

      this.pollInterval = setInterval(() => {
        this.verifyCode();
      }, POLLING_INTERVAL);
    } catch (error) {
      console.error('Error while generating code:', error);
      this.setState({
        value: 'connection error. please try again',
      });
    }
  }

  componentWillUnmount() {
    this.pollInterval && clearInterval(this.pollInterval);
  }

  setCreatedSessionCookie = (sessionInfo: WebSessionInfo) => {
    const domain = getTopLevelDomain();
    document.cookie = `${sessionInfo.cookieName}=${sessionInfo.cookieValue}; domain=${domain}`;
  };

  async verifyCode() {
    const { value, sessionId } = this.state;
    if (!sessionId || !value) {
      return;
    }
    try {
      const { data } = await axios.get<CheckPinResponseStatusType>(
        CHECK_PIN_VERIFIED_API_URL.replace(PIN_PLACEHOLDER, value).replace(
          SESSION_ID_PLACEHOLDER,
          sessionId,
        ),
        { withCredentials: true },
      );

      this.setState({ status: data.status });

      if (data.status === STATUS.UNLOCKED) {
        clearInterval(this.pollInterval);
        if (data.notifyLogin && data.notifyLogin.sessionInfo) {
          this.setCreatedSessionCookie(data.notifyLogin.sessionInfo);
          await window.IdpManager.getCurrentUser(true);
          window.location.reload();
        } else {
          throw new Error(
            `Inconsistent api answer (UNLOCK status but no session info). Are you using the correct API key? (data: ${JSON.stringify(
              data,
            )})`,
          );
        }
      }
    } catch (error) {
      console.error('Error while polling the code:', error);
    }
  }

  close = () => {
    this.props.close();
  };

  render() {
    return (
      <>
        <button onClick={this.close}>Return to the homepage</button>
        {this.state.status === STATUS.LOCKED && (
          <div id="idp-user-settings">
            <div className="idp-user-settings">
              <div className="idp-user-settings-content">
                <div className="card-wrapper" style={styles.wrapper}>
                  <div className="card-info" style={styles.item}>
                    <p>
                      Please login or create an account at{' '}
                      <a href="uefa.tv/firetv">uefa.tv/firetv</a> and when prompted, enter this
                      code:
                    </p>
                    <span style={styles.code}>{this.state.value}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.status === STATUS.UNLOCKED && (
          <div className="idp-user-settings-content">
            <div className="card-wrapper" style={styles.wrapper}>
              <div className="card-info" style={styles.item}>
                <iframe
                  src="https://www.pexels.com/video/clowns-walking-854040/"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TvMockupScreen;
